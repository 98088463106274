(function(){
'use strict';

scCurrencyPicker.$inject = ["$state", "$filter", "$location", "scCurrencyService", "scCampaignsService", "scThemesService", "scOrganizationsService"];
function scCurrencyPicker($state, $filter, $location, scCurrencyService, scCampaignsService, scThemesService, scOrganizationsService) {
  return {
    templateUrl: 'global/directives/scCurrencyPicker/scCurrencyPicker',
    scope: {
      entity: '=?currencyEntity',
      model: '=?currencyModel',
      editable: '<?',
      disabled: '<?',
      selected: '<?'
    },
    link: function link(scope) {
      if (scCampaignsService.active && scCampaignsService.active.current && scCampaignsService.active.current.type !== 'reg_w_fund' && scCampaignsService.active.current.type !== 'ticketed' && $location.path().includes('manage')) {
        scCampaignsService.active.getOverview().then(function (overviewData) {
          if (overviewData.transactions_count > 0) {
            scope.disabled = true;
            scope.tooltipText = 'Goal currency cannot be changed after a campaign has received its first donation.';
          }
        });
      }
      // if the parent context knows whether it wants to make the currency editable, allow
      // it to be set via optional `editable` binding.
      if (!_.isUndefined(scope.editable)) {
        scope.showDropdown = scope.editable;
      } else if (scCampaignsService.active) {
        scope.showDropdown = !scCampaignsService.active.multiCurrencyDisabled();
      } else {
        // campaign model is not yet available when creating a new campaign,
        // so check for campaign type via the state params
        scope.showDropdown = ['crowdfunding', 'p2p', 'donation'].includes($state.params.campaignType) && !scOrganizationsService.active.multiCurrencyDisabled();
      }

      // Currency selection is disabled for orgs without passport (multicurrency disabled) and rwf/ticketed events
      scope.isCurrencySelectionDisabled = function () {
        return scCampaignsService.active.current.type === 'reg_w_fund' || scCampaignsService.active.current.type === 'ticketed' || scOrganizationsService.active.multiCurrencyDisabled();
      };

      scope.optionSwap = function () {
        if (scope.isCurrencySelectionDisabled() && window.newrelic) {
          window.newrelic.log('currency_donor_selection', {
            level: 'info',
            customAttributes: {
              currency: scope.currency.currency,
              url: window.location.href,
              heapUserId: window.heap ? window.heap.userId : undefined,
              referrer: document.referrer
            }
          });
        }

        scope.model = scope.currency.currency;
      };

      scope.pickedCurrency = function () {
        scope.currency = _.filter(scope.currencyOptions, function (option) {
          return option.currency == scope.entityCurrency;
        })[0] || scope.currencyOptions[0];

        if (!_.get(scope, 'currency.fullSymbol', false)) {
          scope.currency.fullSymbol = scCurrencyService.getCurrencySymbol(scope.entityCurrency);
        }

        scope.model = scope.currency.currency;
      };

      scope.changeSpanStyle = function () {
        var styles = {
          color: '#fff',
          'background-color': scThemesService.active.current.styles.primaryColor,
          'background-image': scope.showDropdown ? 'url(/static/global/images/dropdown-arrow.svg' : '',
          'border-radius': 0
        };
        return styles;
      };

      scope.defaultStyle = function () {
        var span = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        var DEFAULT_CURRENCY_PICKER_COLOR = 'rgba(0,0,0,0.6)';

        var color = $filter('adjustColorOpacity')(scThemesService.active ? scThemesService.active.current.styles.primaryColor : DEFAULT_CURRENCY_PICKER_COLOR, 0.12);
        var styles = {
          'border-color': color
        };

        if (span) {
          styles['background-color'] = color;
        }
        return styles;
      };

      scope.changeWrapperStyle = function () {
        var styles = {
          'border-color': scThemesService.active.current.styles.primaryColor
        };
        return styles;
      };

      var currency = scope.model || scCurrencyService.localeCurrency;
      scope.currencyOptions = scCurrencyService.currencies;

      var blackListedCurrency = ['RUB', 'BYN', 'CLF'];

      if (blackListedCurrency.includes(currency)) {
        if (window.newrelic) {
          window.newrelic.log('unset_blacklisted_currency', {
            level: 'trace',
            customAttributes: {
              blacklisted_currency: currency,
              urlParam: currencyParam.toLocaleUpperCase(),
              url: window.location.href,
              heapUserId: window.heap ? window.heap.userId : undefined,
              referrer: document.referrer
            }
          });
        }
        currency = 'USD';
      }

      if (scope.entity === 'donation') {
        scope.currencyOptions = scope.currencyOptions.filter(function (currObj) {
          return !blackListedCurrency.includes(currObj.currency);
        });
      }

      // when a currency param is specified, that takes precedent as long as there are options to choose from and it exists among them
      // Do not use currency url param if campaign is a rwf or ticketed event, or if multicurrency is disabled
      var currencyParam = $state.params.currency;

      // If currency selection should be disabled and there is a url param being sent, log it
      // Currency selection is disabled for orgs without passport (multicurrency disabled) and rwf/ticketed events
      // See CL-46237 for more context
      if (!!currencyParam && scope.isCurrencySelectionDisabled()) {
        if (window.newrelic) {
          window.newrelic.log('currency_url_param', {
            level: 'info',
            customAttributes: {
              urlParam: currencyParam.toLocaleUpperCase(),
              url: window.location.href,
              heapUserId: window.heap ? window.heap.userId : undefined,
              referrer: document.referrer
            }
          });
        }
      }

      if (!scope.isCurrencySelectionDisabled() && currencyParam && scope.currencyOptions.length && scope.currencyOptions.find(function (co) {
        return co.currency === currencyParam.toLocaleUpperCase();
      })) {
        scope.entityCurrency = currencyParam.toLocaleUpperCase();
        scope.pickedCurrency();
        return;
      }

      var defaultCurrency = scCurrencyService.getDefaultCurrency();

      if (scope.showDropdown) {
        if (scope.entity == 'campaign' && !scope.model && !_.get(scCampaignsService, 'active', false)) {
          scope.entityCurrency = currency || scCurrencyService.getDefaultCurrency();
        } else if (scope.entity != 'donation') {
          if (!scope.model && !_.get(scope, 'entity.id', false)) {
            scope.entityCurrency = currency || scCurrencyService.getEntityCurrency(scope.entity);
          } else if (!scope.model) {
            scope.entityCurrency = scCurrencyService.getEntityCurrency(scope.entity);
          } else {
            scope.entityCurrency = scope.model;
          }
        } else if ($state.current.name.indexOf('setup') > -1 || $state.current.name.indexOf('create') > -1 || $state.current.name.indexOf('design') > -1 || SC.preview) {
          // if previewing donation page, instead of displaying
          // localized currency, display the campaign default currency
          // Note that we also have to check SC.preview because the hashbangs in the path to the
          // donation page during preview mode cause the preview stateParam not to be properly
          // registered
          scope.entityCurrency = scCurrencyService.getEntityCurrency(scCampaignsService.active.current);
        } else {
          if (!scope.currencyOptions.length) {
            scope.showDropdown = 0;
            scope.currencyOptions = [{
              currency: defaultCurrency,
              meta: {
                code: defaultCurrency,
                symbol: scCurrencyService.getCurrencySymbol(defaultCurrency)
              }
            }];
          }
          scope.entityCurrency = currency || defaultCurrency;
        }
        scope.pickedCurrency();
      } else {
        scope.entityCurrency = scope.model || defaultCurrency;
        scope.pickedCurrency();
      }
    }
  };
}

angular.module('classy').directive('scCurrencyPicker', scCurrencyPicker);
})();