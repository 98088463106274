(function(){
'use strict';

/*
Suggested Amounts apiv2 response shape (apiv2):
    {
      suggested_donation_amount: 73.89
      suggested_recurring_amount: 81.77
    }
*/

// Load Event
// grid_values - An array that contains the values (<Float>) of each of amounts in the amount grid, starting at the top left and going left-to-right, top-to-bottom (like reading a book). Values should be floats.
// other_value - The amount in the Other field (<Float>)
// default_value - Amount that is preselected (<Float>)
// default_frequency - one-time, daily, weekly, monthly, bi-weekly, quarterly, semi-annually, annually
// iaa_enabled_onetime - <Boolean>
// iaa_enabled_recurring - <Boolean>
// iaa_fallback_shown - <Boolean>
// url_amount_used - Whether or not the ?amount= query param was used in the URL (<Boolean>)
// DCF enabled/opt-in setting

// Later Event
// -- Which grid item was selected when proceeding to next step ← We want to capture this, but perhaps this needs to be captured in a later event.
// -- Upon successful donate event, include -> Which dollar amount was donated
angular.module('classy').constant('IA_HEAP_PREFIX', 'intelligent-ask/').service('cpIntelligentAsk', ["$http", "$q", "$log", "$timeout", "$rootScope", "IA_HEAP_PREFIX", "EmbeddedGivingUtil", "heapService", "scOrganizationsService", function ($http, $q, $log, $timeout, $rootScope, IA_HEAP_PREFIX, EmbeddedGivingUtil, heapService, scOrganizationsService) {
  var _this = this;

  var self = this;
  var HEAP_TIMEOUT = 2000; // update this?

  var campaignId = null;
  var heapLoadEventData = null;
  var heapLoadHandle = null;

  var fetchTimeoutPromise = null;

  this.isInit = false;
  this.isFetching = false;
  this.campaign = null;

  var amountsDeferred = $q.defer();
  this.amountsPromise = amountsDeferred.promise;
  this.amounts = {
    oneTime: undefined,
    recurring: undefined,
    grid: {
      oneTime: undefined,
      recurring: undefined
    }
  };

  function isValidAmounts(amts) {
    // only the 2 fallbacks IAAs are required
    // TODO: should I make it "either" are required?
    return amts && !_.isEmpty(amts) && _.has(amts, 'oneTime') && _.has(amts, 'recurring') && amts.oneTime !== null && amts.recurring !== null;
  }

  function __onHeapLoad() {
    if (heapLoadEventData) {
      heapService.trackIntelligentAskEvent(IA_HEAP_PREFIX + 'load', _.extend({}, heapLoadEventData));
    }

    if (self.isInit) {
      return $q.when(self);
    } else {
      return self.fetch().then(function () {
        return self;
      }).finally(function () {
        self.isInit = true;
        if (_.isFunction(heapLoadHandle)) {
          heapLoadHandle();
        }

        // Cancel fetch timeout
        if (fetchTimeoutPromise) {
          $timeout.cancel(fetchTimeoutPromise);
          fetchTimeoutPromise = null;
        }
      });
    }
  }

  this.init = function (campaign) {
    var fetchAmounts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

    this.campaign = campaign;

    // FIRST check if has access
    if (!this.hasAccess()) {
      $log.debug('[cpIntelligentAsk:init] access denied!');
      return $q.reject();
    }

    // Then wire up Campaign
    if (!campaign || !campaign.saved || !campaign.saved.id) {
      // check if instance of?
      $log.debug('[cpIntelligentAsk:init] campaign is required');
      return $q.reject();
    }
    campaignId = this.campaign.saved.id;

    // Always delay IAA fetch until heap is loaded
    if (fetchAmounts) {
      heapLoadHandle = $rootScope.$on('heapService:load', __onHeapLoad);

      // Set timeout to fetch IAA without heap if heap takes to long
      fetchTimeoutPromise = $timeout(function () {
        // If not init and not fetching, then fetch and get default IAA
        if (!self.isInit && !self.isFetching) {
          // Remove heap load listener if still exists (heap hasnt loaded)
          if (heapLoadHandle) {
            heapLoadHandle();
          }

          return self.fetch().then(function () {
            self.isInit = true;
            return self;
          });
        } else {
          return $q.when(self);
        }
      }, HEAP_TIMEOUT);
      // Return $timeout promise
      return fetchTimeoutPromise;
    } else {
      return $q.when(this);
    }
  };

  this.fetch = function (campaign) {
    if (self.isFetching) {
      $log.debug('[cpIntelligentAsk:fetch] already fetching...');
      return $q.reject();
    }
    self.isFetching = true;

    var c = campaign || this.campaign;
    if (!c || !campaignId) {
      $log.debug('[cpIntelligentAsk:fetch] campaign required');
      return $q.reject();
    }

    var params = {
      screen_height: screen.height || 0,
      screen_width: screen.width || 0
    };

    return $http.get('/frs-api/campaigns/' + campaignId + '/intelligent-ask', { params: params }).then(function (response) {
      $log.debug('[cpIntelligentAsk:fetch] success! response: ', response);
      var data = response.data || {};
      var grid = data.grid;
      var oneTime = data.suggested_donation_amount;
      var recurring = data.suggested_recurring_amount;
      var oneTimeGrid = grid.suggested_donation_amount;
      var recurringGrid = grid.suggested_recurring_amount;

      var amounts = {
        oneTime: oneTime,
        recurring: recurring
      };

      if (grid) {
        if (oneTimeGrid) {
          amounts.grid = amounts.grid || {};
          amounts.grid.oneTime = oneTimeGrid;
        }
        if (recurringGrid) {
          amounts.grid = amounts.grid || {};
          amounts.grid.recurring = recurringGrid;
        }
      }

      self.setAmounts(amounts);
      return self;
    }, function (err) {
      $log.error('[cpIntelligentAsk:fetch] err! response: ', err);
      // cant fail here if we want to use resolver
      return $q.reject(err);
    }).finally(function () {
      self.isFetching = false;
    });
  };

  this.hasAccess = function () {
    return this.hasAccessAmounts() || this.hasAccessSC();
  };

  // Has access to update donation grid?
  // For now only campaigns of type 'donation' have access to IAA
  this.hasAccessAmounts = function () {
    return !!(_this.campaign && _this.campaign.current.type === 'donation' && !scOrganizationsService.getTag('data-use-restriction', true));
  };

  // has access to the SC layer (for A/B testing)
  this.hasAccessSC = function () {
    return !!scOrganizationsService.getTag('iaa-abtest-prerelease', true);
  };

  this.hasGridAmounts = function (frequency) {
    if (frequency === 'oneTime') {
      var oneTimeLevels = _this.getAmountLevelsOneTime();
      return !!(oneTimeLevels && oneTimeLevels.length);
    }

    if (frequency === 'recurring') {
      var recurringLevels = _this.getAmountLevelsRecurring();
      return !!(recurringLevels && recurringLevels.length);
    }

    // If no frequency param passed then check if all grid has values
    return !!(_this.amounts.grid && _this.amounts.grid.oneTime && _this.amounts.grid.recurring);
  };

  // Get one time suggested amounts (the donation grid)
  this.getAmountLevelsOneTime = function () {
    var amounts = _this.amounts.grid.oneTime;

    if (amounts && amounts.length) {
      amounts = amounts.map(function (amount) {
        return {
          amount: amount,
          display_on_page: true,
          displayAmount: amount
        };
      });
      amounts.push({ amount: 'Custom', display_on_page: true });
    }

    return amounts;
  };

  // Get recurring suggested amounts (the donation grid)
  this.getAmountLevelsRecurring = function () {
    var amounts = _this.amounts.grid.recurring;

    if (amounts && amounts.length) {
      amounts = amounts.map(function (amount) {
        return {
          amount: amount,
          display_on_page: true,
          displayAmount: amount
        };
      });
      amounts.push({ amount: 'Custom', display_on_page: true });
    }

    return amounts;
  };

  // Get suggested one time amount
  this.getAmountOneTime = function () {
    return _this.amounts.oneTime;
  };

  // Get suggested recurring amount
  this.getAmountRecurring = function () {
    return _this.amounts.recurring;
  };

  this.sendHeapEvent = function (name) {
    var model = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var stateSnapshot = arguments[2];

    if (!name) {
      $log.debug('[cpIntelligentAsk:sendHeapEvent] @param name required');
      return;
    } else if (!_this.campaign) {
      $log.debug('[cpIntelligentAsk:sendHeapEvent] no campaign present, init() first');
      return;
    }

    if (name === 'load') {
      heapLoadEventData = EmbeddedGivingUtil.getIntelligentAskEventData(_this.campaign, model, stateSnapshot, _this.amounts);
      return;
    }

    // "load" event is required
    if (_.isEmpty(heapLoadEventData)) {
      $log.debug('[cpIntelligentAsk:sendHeapEvent] "load" event must be sent first');
      return;
    }

    var eventData = EmbeddedGivingUtil.getIntelligentAskEventData(_this.campaign, model, stateSnapshot, _this.amounts);
    eventData = _.extend({}, heapLoadEventData, eventData);
    heapService.trackIntelligentAskEvent('' + IA_HEAP_PREFIX + name, eventData);
  };

  this.setAmounts = function (amts) {
    if (!isValidAmounts(amts)) {
      $log.debug('[cpIntelligentAsk:setAmounts] either "oneTime" or "recurring" is required: ', amts);
      amountsDeferred.reject();
      return;
    }

    $log.debug('[cpIntelligentAsk:setAmounts] setting amounts to: ', amts);
    this.amounts = {
      oneTime: amts.oneTime,
      recurring: amts.recurring,
      grid: {
        oneTime: amts.grid && amts.grid.oneTime ? amts.grid.oneTime : undefined,
        recurring: amts.grid && amts.grid.recurring ? amts.grid.recurring : undefined
      }
    }; // always override both

    amountsDeferred.resolve(this.amounts);
    $rootScope.$broadcast('cpIntelligentAsk:amountsUpdated', this.amounts);

    // if has SC org tag (crometrics) for AB testing, then also set SC object
    if (this.hasAccessSC()) {
      this.setSC(this.amounts);
    }
  };

  this.setSC = function (amounts) {
    if (!this.hasAccessSC()) {
      $log.debug('[cpIntelligentAsk:setSC] access denied');
      return;
    } else if (!isValidAmounts(amounts)) {
      $log.debug('[cpIntelligentAsk:setSC] amts not valid, unable to set ', amounts);
      return;
    } else if (!this.campaign) {
      $log.debug('[cpIntelligentAsk:setSC] no campaign present, init() first');
      return;
    }

    SC = SC || {};
    SC.intelligentAsk = _.extend({}, amounts, {
      hasOneTime: this.campaign.hasIntelligentAskOneTime(),
      hasRecurring: this.campaign.hasIntelligentAskRecurring()
    });
    // also set campaign bools
    $log.debug('[cpIntelligentAsk:setSC] SC.intelligentAsk set: ', SC.intelligentAsk);
  };

  this.reset = function () {
    $log.debug('[cpIntelligentAsk:reset] resetting...');

    this.amounts = {
      oneTime: undefined,
      recurring: undefined,
      grid: {
        oneTime: undefined,
        recurring: undefined
      }
    };
  };
}]);
})();