(function(){
'use strict';

/*
 *  This component was initially a copy+paste of the component 'donationAmountRecurringRf'
 *
 *  This component consists of:
 *    - frequency: the one-time and recurring buttons
 *    - donation amount (can be custom)
 *    - donation currency
 */
angular.module('classy').component('egDonationDetails', {
  templateUrl: 'global/embedded-giving/components/donation-details/eg.components.donation-details', // same folder as this
  bindings: {
    donationContext: '=',
    frequencyLevels: '=',
    settingPageFrequency: '<',
    frequency: '=',
    recurUntil: '=',
    levels: '=',
    recurringDonationLevels: '=',
    amount: '=',
    previousFrequencyPrice: '=',
    newAmount: '<',
    currency: '=',
    iaa: '=',
    iaaState: '='
  },
  require: {
    form: '^form'
  },
  controller: ["$state", "$timeout", "cpIntelligentAsk", "accessibilityService", "EmbeddedGivingColors", "scAnalytics", "scBlocksService", "scCampaignsService", "scCurrencyService", "scOrganizationsService", "scThemesService", function controller($state, $timeout, cpIntelligentAsk, accessibilityService, EmbeddedGivingColors, scAnalytics, scBlocksService, scCampaignsService, scCurrencyService, scOrganizationsService, scThemesService) {
    var _this = this;

    var self = this;
    var colorBrandDarkest = 'rgba(0,0,0,0.9)';
    var campaignType = scCampaignsService.active.current.type;

    this.preventOldrfForDeque = scOrganizationsService.active.preventOldrfForDeque();
    this.showRecurringEndDate = scCampaignsService.active.current.hide_recurring_end_date;

    this.recurringFrequencyArray = ['monthly', 'yearly', 'quarterly', 'semi-annually', 'daily', 'weekly', 'bi-weekly'];

    function syncIAA() {
      if (self.preview) {
        self.amount = 0;
        self.otherAmount = 0;
        return;
      }

      var campaign = scCampaignsService.active;

      $timeout(function () {
        if (campaign.hasIntelligentAsk() && cpIntelligentAsk.isInit && cpIntelligentAsk.hasAccessAmounts()) {
          // Custom amount input field
          // Sometimes we get values back even though it's not enabled on the campaign
          var frequency = self.isOneTime() ? 'oneTime' : 'recurring';
          var isFrequencyEnabled = self.isOneTime() ? campaign.hasIntelligentAskOneTime() : campaign.hasIntelligentAskRecurring();

          if (isFrequencyEnabled && // If the active frequency is IAA enabled
          !cpIntelligentAsk.hasGridAmounts(frequency) && // If the active frequency has no grid amounts
          self.iaaState[frequency] // and if there is a default IAA amount for the frequency
          ) {
              // Update the other amount field and select it
              self.showCustomAmtSelected = true;
              self.otherAmount = self.iaaState[frequency];
              self.amount = self.iaaState[frequency];
            }
        }
      });
    }

    /* If we pass through the amount params, make sure the correct button is selected, and if the
     * preset amount is not in the preset donation levels, make sure the 'Custom' button is selected
     * TODO: this logic can be reduced and simplified by ~75%, make task */
    function initAmounts() {
      var presetAmt = _.get($state, 'params.amount', false);

      // If query paramter is at the wrong place in the URL, then it is not populated in `params.amount`
      // For example, /give/{orgId}?amount=26#!/donation/checkout
      if (!presetAmt && self.amount !== 0) {
        presetAmt = self.amount;
      }

      self.showCustomAmtSelected = self.amount === 'Custom';
      // If custom is selected as default
      if (self.showCustomAmtSelected) {
        self.amount = '';
      }

      // If custom is selected as default
      if (self.previousFrequencyPrice === 'Custom') {
        self.previousFrequencyPrice = '';
      }

      self.showRecurringDonationArray = self.frequency !== 'one-time';

      var donationLevels = self.showRecurringDonationArray ? self.recurringDonationLevels : self.levels;

      var filteredLevels = _.filter(donationLevels, function (level) {
        return level.display_on_page === true;
      });

      if (presetAmt && !filteredLevels.some(function (i) {
        return i.amount == presetAmt;
      })) {
        if (filteredLevels.find(function (i) {
          return i.amount === 'Custom' && i.display_on_page === true;
        })) {
          self.showCustomAmtSelected = true;
          self.otherAmount = isNaN(presetAmt) ? 0 : presetAmt;
        } else if (!filteredLevels.find(function (i) {
          return i.amount === 'Custom' && i.display_on_page === true;
        }) && filteredLevels.length && filteredLevels.length !== 1) {
          self.amount = self.showRecurringDonationArray ? self.DonationBlock.current['recurring-donation-default'] || 0 : self.DonationBlock.current['donation-default'] || 0;
        } else {
          self.amount = isNaN(presetAmt) ? 0 : presetAmt;
        }
      }

      // TODO: preset amt on other field if exists
      syncIAA();
    }

    this.$onInit = function () {
      // Flag to change donation array values according to amount pass through parameter.
      /** NEW RF CHANGES: ---------------------------------------------*/
      _this.DonationBlock = scBlocksService.getBlockByType('donation');
      _this.defaultFrequency = _this.frequency || _this.DonationBlock.current['recurring-default'];
      _this.visible = false;
      _this.today = new Date();
      _this.today.setDate(_this.today.getDate() + 1);

      _this.theme = scThemesService.active.current;
      _this.showCustomAmtSelected = false;
      _this.frequencyToggleClick = 0;

      _this.colorPrimary = EmbeddedGivingColors.getPrimary(scThemesService.active);
      _this.colorAccent = EmbeddedGivingColors.getAccent(scThemesService.active);
      _this.colorAccentOpaque = EmbeddedGivingColors.getAccentOpaque(scThemesService.active);

      // TODO refactor to check for isPreview
      _this.getColorPrimary = function () {
        return EmbeddedGivingColors.getPrimary(scThemesService.active);
      };
      _this.getColorAccent = function () {
        return EmbeddedGivingColors.getAccent(scThemesService.active);
      };
      _this.getColorAccentOpaque = function () {
        return EmbeddedGivingColors.getAccentOpaque(scThemesService.active);
      };
      // parse the primary color for its RGB value -- we'll be customizing the opacity
      _this.ctaStyle = { 'background-color': _this.getColorAccentOpaque() };

      /** _________________________________________________________________________  */

      if (!scCampaignsService.active.multiCurrencyDisabled()) {
        _this.currency = scCurrencyService.localeCurrency;
      }

      // Show dark outline around scCurrencyPicker input on focus, hide on blur
      _this.show = {
        inputFocus: false
      };

      initAmounts();
      _this.analyticsInitialized = true;
    };

    // TODO: remove all these style getters.. refactor to best practice simple ng-class
    this.getStylesHoverFrequencyBtn = function (radioButtonValue, isHover) {
      var checkSelected = angular.element('.hover-state_' + radioButtonValue).hasClass('selected');

      if (!checkSelected) {
        angular.element('.hover-state_' + radioButtonValue).css({
          color: isHover ? _this.getColorPrimary() : colorBrandDarkest
        });
      }
    };

    this.getStylesFrequencyOneTimeBtn = function (setting) {
      var isSelected = _this.frequency == setting;
      return {
        transition: 'all 0.2s ease-in-out',
        border: '2px solid ' + _this.getColorAccentOpaque(),
        color: _this.isOneTime() ? '#fff' : colorBrandDarkest,
        'background-color': isSelected ? _this.getColorPrimary() : _this.getColorAccentOpaque(),
        'border-radius': '24px'
      };
    };

    this.getStylesFrequencyRecurringBtn = function (setting) {
      var isActive = _this.recurringFrequencyArray.includes(setting);
      return {
        transition: 'all 0.2s ease-in-out',
        border: '2px solid ' + _this.getColorAccentOpaque(),
        color: isActive ? '#fff' : colorBrandDarkest,
        'background-color': isActive ? _this.getColorPrimary() : _this.getColorAccentOpaque(),
        'border-radius': '24px'
      };
    };

    this.getStylesDonationAmountBtn = function (displayAmt) {
      var isSelected = !_this.showCustomAmtSelected && _this.amount === displayAmt;
      return {
        transition: 'all 0.2s ease-in-out',
        border: '2px solid ' + (isSelected ? _this.getColorPrimary() : _this.getColorAccentOpaque()),
        color: isSelected ? '#fff' : colorBrandDarkest,
        height: '56px',
        margin: '2px',
        'background-color': isSelected ? _this.getColorPrimary() : _this.getColorAccentOpaque(),
        'border-radius': '8px',
        'box-shadow': isSelected ? 'inset 1px 2px 12px rgba(0,0,0,0.2), inset 1px 2px 2px rgba(0, 0, 0, 0.2)' : 'none'
      };
    };

    this.getStylesHoverDonationAmountBtn = function (radioButtonValue, isHover) {
      if (isHover) {
        angular.element('.hover-state_' + radioButtonValue).css({
          color: '#fff',
          'background-color': _this.getColorPrimary()
        });
      } else {
        var checkSelected = angular.element('.hover-state_' + radioButtonValue).hasClass('selected');

        if (!checkSelected) {
          angular.element('.hover-state_' + radioButtonValue).css({
            color: colorBrandDarkest,
            'background-color': _this.getColorAccentOpaque()
          });
        }
      }
    };

    this.showFrequencyForDeque = function (frequency) {
      return !['daily', 'weekly', 'bi-weekly'].includes(frequency);
    };

    this.$onChanges = function (options) {
      if (options.newAmount && !!options.newAmount.currentValue) {
        _this.value = options.newAmount.currentValue;
      }

      if (options.settingPageFrequency) {
        _this.freqValue = options.settingPageFrequency.currentValue;
        if (_this.analyticsInitialized) {
          scAnalytics.eventBeacon({
            category: 'frs ' + campaignType,
            action: 'click',
            label: 'Donation Frequency \u2013 ' + options.settingPageFrequency.currentValue + ' (non-text link)'
          });
        }
      }

      _this.isDonationPage = $state.params.step === 'donation';

      // Since there is 2 preview mode: one is in campaign setting donation page and second one is in donation checkout page
      // following condition is made for campaign setting donation preview mode.
      if (_this.preview && _this.isDonationPage) {
        if (_this.freqValue) {
          _this.showDropdownOnPreview(_this.freqValue);
        }
      }

      if (options.iaaState && !options.iaaState.isFirstChange()) {
        var oldValue = options.iaaState.previousValue;
        var newValue = options.iaaState.currentValue;

        // Trigger logic when iaaState changes
        if (oldValue !== newValue) {
          syncIAA();
        }
      }
    };

    /**
     * NEW RF CHANGES:------------------------------------------------
     */

    this.recurringLevels = function (levels) {
      return levels.filter(function (level) {
        return level.type !== 'one-time' && level.display_on_page;
      });
    };

    this.onlyReccurringFrequency = function (levels) {
      return !levels.some(function (level) {
        return level.type === 'one-time' && level.display_on_page;
      });
    };

    this.recurringBtnText = function () {
      return _this.recurringLevels(_this.frequencyLevels).length > 1 ? 'recurring' : _this.recurringLevels(_this.frequencyLevels)[0].type;
    };

    this.recurringBtnSelected = function (frequency) {
      return _this.recurringFrequencyArray.includes(frequency);
    };

    this.showFreqDropdown = function (freqType) {
      if (freqType === 'one-time') {
        _this.isFreqDropdown = false;
        _this.frequency = 'one-time';
        _this.recurUntil = null;
      } else {
        _this.isFreqDropdown = false;

        if (freqType === 'recurring' || freqType === 'monthly') {
          _this.frequency = 'monthly';
        } else {
          _this.frequency = _this.defaultFrequency;
        }
      }
    };
    /** ______________________________________________________ */

    this.isRecurringFrequency = function (event) {
      if (_this.preventOldrfForDeque) return;

      if (event.currentTarget && event.currentTarget.classList.contains('selected')) return;

      _this.showRecurringDonationArray = !_this.preventOldrfForDeque && _this.frequency !== 'one-time';

      _this.frequencyToggleClick += 1;

      // toggle internal raw_final_price & previous_frequency_price
      var temp = _this.previousFrequencyPrice;
      _this.previousFrequencyPrice = _this.amount;
      _this.amount = temp;

      // while toggle other amount should be blank
      _this.otherAmount = _this.showCustomAmtSelected ? '' : _this.otherAmount;
      _this.previousFrequencyPrice = _this.showCustomAmtSelected ? 0 : _this.previousFrequencyPrice;
      _this.showCustomAmtSelected = !!_this.otherAmount;

      var presetAmt = _.get($state, 'params.amount', false);

      // If frequencyToggleClick = 1 i.e only first click of toggle
      // then only change array value according to amount pass through parameter.
      if (_this.frequencyToggleClick === 1 && presetAmt) {
        var donationLevels = _this.showRecurringDonationArray ? _this.recurringDonationLevels : _this.levels;
        var filteredLevels = _.filter(donationLevels, function (level) {
          return level.display_on_page === true;
        });

        if (presetAmt && !filteredLevels.some(function (i) {
          return i.amount == presetAmt;
        })) {
          if (filteredLevels.find(function (i) {
            return i.amount === 'Custom' && i.display_on_page === true;
          })) {
            _this.showCustomAmtSelected = true;
            _this.otherAmount = isNaN(presetAmt) ? 0 : presetAmt;
          } else if (!filteredLevels.find(function (i) {
            return i.amount === 'Custom' && i.display_on_page === true;
          }) && filteredLevels.length && filteredLevels.length !== 1) {
            _this.amount = _this.showRecurringDonationArray ? _this.DonationBlock.current['recurring-donation-default'] || 0 : _this.DonationBlock.current['donation-default'] || 0;
          } else {
            _this.amount = isNaN(presetAmt) ? 0 : presetAmt;
          }
        }
      }
    };

    this.noOfFrequencyLevel = function (frequencyLevels) {
      return frequencyLevels.filter(function (level) {
        return level.display_on_page;
      }).length;
    };

    this.noOfDonationAmount = function (donationAmounts) {
      return donationAmounts.filter(function (item) {
        return item.display_on_page;
      }).length;
    };

    this.showCustomAmtField = function (levels) {
      var displayed = levels.some(function (cLevel) {
        return cLevel.display_on_page && cLevel.amount === 'Custom';
      });

      if (!displayed) {
        _this.showCustomAmtSelected = false;
      }
      return displayed;
    };

    this.canShowLevel = function (level) {
      if (_this.preview) {
        return true;
      }

      if (_.isEmpty(level) || level.loading) {
        return false;
      }

      if (_this.iaa && _this.iaa.hasAccess() && _this.iaa.isFetching && !_this.iaa.isInit) {
        return false;
      }

      return true;
    };

    this.getAriaLabel = function (frequency) {
      var ariaLabel = void 0;
      switch (frequency) {
        case 'one-time':
        case 'monthly':
        case 'yearly':
        case 'weekly':
        case 'daily':
          ariaLabel = 'Make a ' + frequency + ' gift';
          break;
        case 'quarterly':
          ariaLabel = 'Make a gift every three months';
          break;
        case 'semi-annually':
          ariaLabel = 'Make a gift every six months';
          break;
        case 'bi-weekly':
          ariaLabel = 'Make a gift every two weeks';
          break;
        default:
          break;
      }
      return ariaLabel;
    };

    this.isOneTime = function () {
      return _this.frequency === 'one-time';
    };

    this.selectFrequency = function (frequency, keyboardEvent, element) {
      if (keyboardEvent && !accessibilityService.isValidKeyBoardEvent(keyboardEvent)) return;
      /** NEW RF CHANGES: On tab and enter key press select the toggle frequency */
      if (keyboardEvent && (keyboardEvent.keyCode === 13 || keyboardEvent.keyCode === 32)) {
        if (element == 'selectDropdown') {
          keyboardEvent.preventDefault();
        } else if (frequency === 'one-time') {
          _this.frequency = frequency;
          _this.isFreqDropdown = false;
        } else {
          _this.frequency = _this.defaultFrequency === 'one-time' ? _this.recurringLevels(_this.frequencyLevels)[0].type : _this.defaultFrequency;
          _this.isFreqDropdown = frequency !== 'one-time' && _this.recurringLevels(_this.frequencyLevels).length > 1;
        }
      }

      syncIAA();
    };

    this.setPreventDefault = function (event) {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    };

    this.selectAmount = function (amount, keyboardEvent) {
      var selectedDonationLevels = !_this.preventOldrfForDeque && _this.frequency !== 'one-time' ? _this.recurringDonationLevels : _this.levels;

      _this.isOtherInputPresent = selectedDonationLevels.some(function (level) {
        return level.amount === 'Custom' && level.display_on_page;
      });

      if (_this.isOtherInputPresent) {
        if (amount !== 'Custom') {
          _this.otherAmount = '';

          $timeout(function () {
            _this.form.amount.$setValidity('required', true);
            _this.form.amount.$setValidity('min', true);
            _this.form.amount.$setValidity('max', true);
          });
        }
      }

      if (keyboardEvent && !accessibilityService.isValidKeyBoardEvent(keyboardEvent)) return;
      _this.showCustomAmtSelected = amount === 'Custom';

      if (!_this.showCustomAmtSelected) {
        _this.amount = amount;
      } else {
        _this.amount = _this.otherAmount || 0;
      }
    };
  }]
});
})();